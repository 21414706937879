@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: black;
    color: white;
    font-family: 'Noto Serif', serif;
}
